import React from 'react';
import styled from 'styled-components';

function ButtonDes() {
	return (
		<StyledButtonDes>
			<div className='wrap'>
				<p>
					​ Partner visas are costly and can take a long time to be processed.
					It is not worth making a simple mistake that could result in thousands
					of dollars and years lost. Contact us today for professional and
					up-to-date advice before lodging your Partner Visa application. We can
					make it easy and handle the tricky bits for you.​
				</p>

				<button className='hero_button'>Get In Touch</button>
			</div>
		</StyledButtonDes>
	);
}

const StyledButtonDes = styled.div`
	background: rgba(233, 235, 238, 0.2);
	.wrap {
		margin: 60px auto;
		max-width: 1150px;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 471px;

		@media only screen and (max-width: 1200px) {
			padding: 0 15px;
		}
	}
	p {
		font-family: SF Pro Text;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 150%;
		/* or 30px */

		text-align: center;

		/* Black */

		color: #04152f;
		max-width: 805px;
		width: 100%;
		margin-bottom: 60px;

		@media only screen and (max-width: 600px) {
			font-size: 17px;
		}
	}

	.hero_button {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 1.8rem;
		display: flex;
		align-items: center;
		text-align: center;
		color: #ffffff;
		padding: 1.4rem 4.3rem;
		width: 17rem;
		height: 4.6rem;
		background: #183fc7;
		border-radius: 0.5rem;
		outline: none;
		border: none;
		cursor: pointer;
		margin-top: 30px;
	}
`;

export default ButtonDes;
