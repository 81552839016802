import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../images/fullLogo.svg';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
function Nav({ active, setActive }) {
	const navigate = useNavigate();
	const [isShown, setIsShown] = useState(false);

	const routeHandler = (route) => {
		setIsShown(false);
		navigate(route);
	};
	return (
		<NavStyle onMouseLeave={() => setIsShown(false)}>
			<LogoStyle onClick={() => navigate('/')}>
				<img src={Logo} alt='' />
			</LogoStyle>

			<ContentStyle>
				<li
					onMouseEnter={() => setIsShown(true)}
					onClick={() => setIsShown(false)}>
					Services
					{isShown && (
						<StyledPopup>
							<li onClick={() => routeHandler('/studentvisa')}>
								Student Visas
							</li>
							<li onClick={() => routeHandler('/graduatevisa')}>
								Graduate Visas
							</li>
							<li onClick={() => routeHandler('/partnervisa')}>
								Partners Visas
							</li>
							{/* <li onClick={() => routeHandler('/notfound')}>Skilled Visas</li>
							<li onClick={() => routeHandler('/notfound')}>Sponsored Visas</li>
							<li onClick={() => routeHandler('/notfound')}>Other Services</li> */}
						</StyledPopup>
					)}
				</li>
				<li onClick={() => navigate('/aboutus')}>About Us</li>
				<li onClick={() => navigate('/contactus')}>Contact Us</li>
			</ContentStyle>

			<HamburgerButton>
				<FontAwesomeIcon
					onClick={() => setActive(true)}
					className='icon icon-bar'
					icon={faBars}
					size='3x'
					// style={{ display: active ? 'none' : '' }}
				/>
			</HamburgerButton>
		</NavStyle>
	);
}

const NavStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: 75%; */
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	padding: 3rem 0;
	@media only screen and (max-width: 1200px) {
		padding: 3rem 6rem;
	}

	@media only screen and (max-width: 480px) {
		padding: 3rem 16px;
	}
`;

const LogoStyle = styled.div`
	cursor: pointer;
`;

const ContentStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	@media only screen and (max-width: 600px) {
		display: none;
	}

	li {
		margin: 0 2rem;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 1.6rem;
		line-height: 2.2rem;
		display: flex;
		align-items: center;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
	}

	li:last-child {
		margin-right: 0;
	}
`;

const HamburgerButton = styled.div`
	transition: all 1s ease;
	display: none;
	@media only screen and (max-width: 600px) {
		display: block;
	}
	.icon-bar {
		color: #ffffff;
		display: none;

		@media only screen and (max-width: 600px) {
			display: block;
		}
	}
`;

const StyledPopup = styled.div`
	clip-path: polygon(0 7%, 29% 7%, 58% 0, 50% 8%, 100% 8%, 100% 100%, 0 100%);
	background-color: #183fc6;
	width: 20rem;
	position: absolute;
	top: 2rem;
	left: -6rem;
	padding-top: 2rem;
	z-index: 99;
	transistion: all 250ms ease;

	li {
		margin: 1rem 0rem;
		padding-bottom: 1rem;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 1.6rem;
		line-height: 2.2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: #ffffff;
		cursor: pointer;

		border-bottom: 1px solid black;
	}

	li:last-child {
		border-bottom: none;
	}
`;
export default Nav;
