import React from 'react';
import styled from 'styled-components';
import ContactHero from '../components/ContactHero';
import Footer from '../components/Footer';
import FormCom from '../components/FormCom';

function ContactUs({ active, setActive }) {
	return (
		<StyledContact>
			<ContactHero active={active} setActive={setActive} />
			<FormCom title='Ola Migration Agent Contact' />
			<Footer />
		</StyledContact>
	);
}

const StyledContact = styled.div``;

export default ContactUs;
