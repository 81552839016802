import React from 'react';
import styled from 'styled-components';
import Schoollogo from './Schoollogo';

function Partner() {
	return (
		<StyledPartner>
			<h3>Some of our partners</h3>
			<Schoollogo />
		</StyledPartner>
	);
}

const StyledPartner = styled.div`
	margin: 120px auto;
	h3 {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		line-height: 60px;

		/* Black */
		text-align: center;
		color: #04152f;
		margin-bottom: -5rem;

		@media only screen and (max-width: 1200px) {
			font-size: 40px;
		}
		@media only screen and (max-width: 600px) {
			font-size: 32px;
		}
	}
`;
export default Partner;
