import React from 'react';
import styled from 'styled-components';
import image from '../images/pic5.png';

function AboutDes() {
	return (
		<StyledDes>
			<div className='left'>
				<img src={image} alt='' />

				<div className='left_hide_content'>
					<p className='right_content_third'>
						<span className='right_span'>Jasmine Sallis, </span>Principle
						Migration Agent (MARN: 1800400)
					</p>
					<p className='right_content_second'>
						“We are focused on getting you the Australian migration outcomes you
						once only dreamed of. We do the impossible. We get you here and on
						the right path to achieving your goals.“
					</p>
				</div>
			</div>

			<div className='right'>
				<h3 className='rightH3'>
					We specialise in hassle-free Australian Visa applications from the
					regions of Nigeria and Kenya
				</h3>
				<p className='right_content'>
					Jasmine Sallis has worked in the migration field for over 8 years.
					Since becoming a Registered Migration Agent she has successfully
					lodged Partner Visas, Skilled Visas, Employer Nominated applications,
					Student Visas, Graduate Visas, Tourist Visas and Temporary work Visas.
					In addition, she has successfully represented clients at the
					Administrative Appeals Tribunal to have their refused visa
					applications overturned.
				</p>
				<hr className='line' />
				<div className='right_hide_content'>
					<p className='right_content_second'>
						“We are focused on getting you the Australian migration outcomes you
						once only dreamed of. We do the impossible. We get you here and on
						the right path to achieving your goals. “
					</p>
					<p className='right_content_third'>
						<span className='right_span'>Jasmine Sallis, </span>Principle
						Migration Agent (MARN: 1800400)
					</p>
				</div>
			</div>
		</StyledDes>
	);
}

const StyledDes = styled.div`
	display: flex;
	align-items: flex-start;
	margin: 200px auto;
	max-width: 1150px;
	width: 100%;

	@media only screen and (max-width: 1200px) {
		padding: 0 6rem;
	}

	@media only screen and (max-width: 1050px) {
		flex-direction: column-reverse;
		align-items: center;
	}
	@media only screen and (max-width: 480px) {
		padding: 0 3rem;
		margin: 200px auto 60px auto;
	}
	.left {
		flex: 1;
		@media only screen and (max-width: 1050px) {
			margin-top: 3rem;
		}

		.left_hide_content {
			display: none;

			.right_content_second {
				font-family: SF Pro Text;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 20px;

				/* Black */

				color: #04152f;
				margin: 15px 0;
			}
			.right_content_third {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 150%;
				/* or 30px */

				/* Black */

				color: #04152f;
			}
			.right_span {
				font-weight: bold;
			}

			@media only screen and (max-width: 480px) {
				display: block;
			}
		}
	}

	.right {
		flex: 1;
		.rightH3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 150%;
			color: #050505;

			@media only screen and (max-width: 480px) {
				font-size: 28px;
			}
		}

		.right_hide_content {
			@media only screen and (max-width: 480px) {
				display: none;
			}
		}

		.right_content {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;
			margin: 15px 0 30px 0;
			@media only screen and (max-width: 480px) {
				font-size: 17px;
			}
		}

		.right_content_second {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 20px;

			/* Black */

			color: #04152f;
			margin: 15px 0;
		}
		.right_content_third {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;
		}
		.right_span {
			font-weight: bold;
		}

		.line {
			@media only screen and (max-width: 480px) {
				display: none;
			}
		}
	}
`;

export default AboutDes;
