import React from 'react';
import styled from 'styled-components';
import pic10 from '../images/pic10.png';
import pic11 from '../images/pic11.png';
import pic12 from '../images/pic12.png';
// import AboutDesTeam from './AboutDesTeam';
import image1 from '../images/jasmine.jpeg';
import image2 from '../images/olamigrate.jpeg';
import image3 from '../images/fasan.jpeg';

function Team() {
	return (
		<StyledTeam>
			<h3>Meet The Team</h3>
			{/* <AboutDesTeam /> */}
			<div className='wrapper'>
				<div className='wrapper_1'>
					<img className='image1' src={image1} alt='' />
					<h5>
						Principle Migration Agent{' '}
						<span style={{ fontSize: '14px', fontWeight: 900 }}>
							(MARN 1800400)
						</span>
					</h5>
					<h2>Jasmine Sallis</h2>
				</div>

				<div className='wrapper_1'>
					<img className='image2' src={image2} alt='' />
					<h5>Admission Lead - Kenya Office</h5>
					<h2>Ednah Maranga </h2>
				</div>

				<div className='wrapper_1'>
					<img className='image3' src={image3} alt='' />
					<h5>Admission Lead - Nigeria Office</h5>
					<h2>Ayoola Femi-Fasan</h2>
				</div>
			</div>
		</StyledTeam>
	);
}

const StyledTeam = styled.div`
	max-width: 1150px;
	width: 100%;
	margin: 120px auto;
	@media only screen and (max-width: 1200px) {
		padding: 0 6rem;
	}

	@media only screen and (max-width: 480px) {
		padding: 0 3rem;
	}
	h3 {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 150%;
		color: #04152f;
		margin-bottom: 60px;
	}

	.wrapper {
		display: grid;
		grid-template-columns: 350px 350px 350px;
		grid-gap: 3rem;
		justify-content: center;
		align-items: center;
		/* max-width: 1150px;
		width: 100%; */

		@media only screen and (max-width: 1200px) {
			grid-template-columns: 310px 310px 310px;
			/* grid-gap: 6rem; */

			justify-content: flex-start;
			align-items: flex-start;
		}

		@media only screen and (max-width: 1100px) {
			display: flex;
			overflow-x: scroll;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none;
			}
		}
		img {
			margin-bottom: 20px;

			@media only screen and (max-width: 1200px) {
				width: 300px;
				height: 300px;
				object-fit: cover;
			}

			@media only screen and (max-width: 1100px) {
				width: 350px;
				height: 350px;
				object-fit: cover;
			}

			@media only screen and (max-width: 480px) {
				width: 228px;
				height: 263px;
				object-fit: cover;
			}
		}

		.wrapper_1 {
			/* width: 350px; */
			min-width: 340px;

			.image1 {
				width: 342px;
				height: 363px;
				object-fit: cover;
				object-position: top;
			}
			.image2 {
				width: 352px;
				height: 363px;
				object-fit: cover;
				object-position: top;
			}
			.image3 {
				width: 348px;
				height: 363px;
				object-fit: cover;
				object-position: top;
			}

			@media only screen and (max-width: 480px) {
				min-width: 250px;
			}

			h5 {
				font-family: SF Pro Text;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 150%;
				/* identical to box height, or 36px */

				color: #000000;
			}

			h2 {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: 600;
				font-size: 30px;
				line-height: 150%;

				color: #000000;
				@media only screen and (max-width: 480px) {
					font-size: 24px;
				}
			}
		}
	}
`;

export default Team;
