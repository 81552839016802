import React from 'react';
import styled from 'styled-components';
import howe from '../images/Pics and bgII.png';

function HowItWorkIII() {
	return (
		<StyledHow>
			<div className='left'>
				<h3 className='right_h3'>How it works</h3>
				<p className='right_p1'>
					We have extensive experience and a 100% success rate of being granted
					Partner Visas. Partner Visas are for applicants who are in an
					exclusive relationship with an Australian Citizen, Permanent Resident
					or eligible NZ Citizen.
					<br /> <br /> <br />
					As you can imagine when people come to study in Australia they often
					meet their future spouse. This is where our expertise come in. We
					assist couples to understand the legal requirements and process of
					lodging and having an Australian partner visa approved. Trust us, it
					is often more complicated that you would think.
				</p>
				<button className='hero_button'>Get In Touch</button>
			</div>

			<div className='right'>
				<h3 className='right_h3'>How it works</h3>

				<img src={howe} alt='' />
			</div>
		</StyledHow>
	);
}

const StyledHow = styled.div`
margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	padding: 80px 0px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media only screen and (max-width: 1200px) {
		flex-direction: column-reverse;
		align-items: center;
	}
	@media only screen and (max-width: 600px) {
		padding: 60px 0px 0px 0px;
	}

	.left {
		display; flex;
		flex-direction: column;
			justify-content: space-between;
	align-items: flex-start;
	@media only screen and (max-width: 1200px) {
			margin-left: 0;
			padding: 0 15px;
		}
		.right_h3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;
@media only screen and (max-width: 1200px) {
				display: none;
			}

			@media only screen and (max-width: 600px) {
				font-size: 32px;
			}
		
			
		}

		.right_p1 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 180%;
			/* or 30px */

			/* Black */

			color: #04152f;

				@media only screen and (max-width: 600px) {
				font-size: 17px;
			}
		}

		.right_p2 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			color: #000000;

		}

		.linker {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			color: #3657ce;
		}
		.hero_button {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 1.5rem;
			line-height: 1.8rem;
			display: flex;
			align-items: center;
			text-align: center;
			color: #ffffff;
			padding: 1.4rem 4.3rem;
			width: 17rem;
			height: 4.6rem;
			background: #183fc7;
			border-radius: 0.5rem;
			outline: none;
			border: none;
			cursor: pointer;
			margin-top: 30px;
		}
	}

	.right {
		margin-left: 72px;
@media only screen and (max-width: 1200px) {
			margin-left: 0;
			padding: 0 15px;
		}
		.right_h3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;
			display: none;
			@media only screen and (max-width: 1200px) {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			@media only screen and (max-width: 600px) {
				font-size: 32px;
				justify-content: flex-start;
				align-items: flex-start;
				/* margin-left: -30px; */
			}
		}

		img {
			@media only screen and (max-width: 600px) {
				width: 400px;
				height: 400px;
				object-fit: cover;
				margin-bottom: 3rem;
			}

			@media only screen and (max-width: 430px) {
				width: 265.81px;
				height: 293.23px;
				object-fit: cover;
			}
		}
	}
`;

export default HowItWorkIII;
