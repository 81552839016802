import React from 'react';
import styled from 'styled-components';
import studentvisa from '../images/studentvisahome.png';
import graduatevisa from '../images/graduatevisahome.png';
import partnervisa from '../images/partnervisahome.png';
import skilledvisa from '../images/skilledvisahome.png';
import sponsorvisa from '../images/sponsoredvisahome.png';
import others from '../images/othervisahome.png';
import Card from './Card';

function MajorServices({ loading }) {
	const data = [
		{
			title: 'Student Visas',
			content:
				'At OLA Migration we take care of your student visa from the first step to the final step. Starting with gaining admission into your chosen course of study and institution all the way to being granted the visa, complying with visa conditions and preparing for subsequent visa applications. Everything is taken care of including health  insurance, institution admissions and visa applications. Student visas are often a first step into Australia and our team makesure we cater for those of you  coming to Australia with very little knowledge of the visa system,  requirements and compliance issues that can arise.',
			image: `${studentvisa}`,
			link: 'studentvisa',
		},
		{
			title: 'Graduate Visas',
			content:
				'For international students Australia offers a wonderful graduate visa program once studies are completed. This visa can be granted for anywhere between 1.5 to  4 years. For applicants that study in regional Australia longer graduate visas can  be granted. At OLA Migration we discuss the requirements of Graduate  Visas well before an applicant is eligible to ensure they can use their time  as a student visa holder in Australia to make the most of their  Graduate Visa opportunity. ',
			image: `${graduatevisa}`,
			link: 'graduatevisa',
		},
		{
			title: 'Partners Visas',
			content:
				'A Partner Visa allows an Australian Citizen or Permanent Resident to sponsor their spouse or de facto partner to live with them in Australia. The Partner Visa process occurs in two stages with a temporary visa issued first and a permanent visa after 2 years if the relationship is continuing.',
			image: `${partnervisa}`,
			link: 'partnervisa',
		},
		// {
		// 	title: 'Skilled Visas',
		// 	content:
		// 		'Australia is focused on attracting highly skilled workers to the country. Under the General Skilled Migration program (GSM) applicants are',
		// 	image: `${skilledvisa}`,
		// 	link: 'notfound',
		// },
		// {
		// 	title: 'Employers Sponsored Visas',
		// 	content:
		// 		'The highly coveted Employer Sponsored visas can often seem like a distant dream; however, Australian employers are often in desperate',
		// 	image: `${sponsorvisa}`,
		// 	link: 'notfound',
		// },
		// {
		// 	title: 'Other Migration Services',
		// 	content:
		// 		'Often individuals find themselves with other Australian migration enquiries. We offer a consultation service for these situations where you can speak',
		// 	image: `${others}`,
		// 	link: 'notfound',
		// },
	];
	return (
		<StyledMajor>
			<h2 className='major_heading'>Our Major Services</h2>
			<div className='data_i'>
				{data.map((item, i) => (
					<Card item={item} key={i} loading={loading} />
				))}
			</div>
		</StyledMajor>
	);
}
const StyledMajor = styled.div`
	/* width: 75%; */
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	.major_heading {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 5rem;
		line-height: 6rem;
		color: #04152f;
		text-align: center;
		margin-bottom: 6rem;
	}
	.data_i {
		display: grid;
		grid-template-columns: 350px 350px 350px;
		grid-gap: 3rem;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: 1200px) {
			grid-template-columns: 350px 350px;
		}

		@media only screen and (max-width: 940px) {
			grid-template-columns: 350px;
		}
	}
`;
export default MajorServices;
