import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import GlobalStyles from './components/GlobalStyles';
import HomePage from './pages/HomePage';
import About from './pages/About';
import StudentVisa from './pages/StudentVisa';
import ContactUs from './pages/ContactUs';
import PartnerVisa from './pages/PartnerVisa';
import GraduateVisa from './pages/GraduateVisa';
import Notfound from './components/Notfound';

function App() {
	const location = useLocation();
	const [active, setActive] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<StyledApp>
			<GlobalStyles />

			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={location.pathname}>
					<Route
						path='/'
						element={<HomePage active={active} setActive={setActive} />}
						exact
					/>
					<Route
						path='/aboutus'
						element={<About active={active} setActive={setActive} />}
						exact></Route>
					<Route
						path='/studentvisa'
						element={<StudentVisa active={active} setActive={setActive} />}
						exact></Route>
					<Route
						path='/contactus'
						element={<ContactUs active={active} setActive={setActive} />}
						exact></Route>
					<Route
						path='/partnervisa'
						element={<PartnerVisa active={active} setActive={setActive} />}
						exact></Route>
					<Route
						path='/graduatevisa'
						element={<GraduateVisa active={active} setActive={setActive} />}
						exact></Route>
					<Route path='/notfound' element={<Notfound />} exact></Route>
				</Routes>
			</AnimatePresence>
		</StyledApp>
	);
}

const StyledApp = styled.div`
	position: relative;
	width: 100%;
	/* width: 100%;
	margin: 0 auto; */
	/* max-width: 1150px; */
`;

export default App;
