import React from 'react';
import styled from 'styled-components';
import img from '../images/joined2.png';

function Growth() {
	return (
		<StyledGrowth>
			<div className='left'>
				<h2>
					Our growth is driven by
					<br /> world class employees
					<br /> and trusted partners.
				</h2>
				<img src={img} alt='' />
			</div>

			<div className='right'>
				<h2 className='right_content'>
					Our growth is driven by
					<br /> world class employees
					<br /> and trusted partners.
				</h2>

				<div className='right_content_top'>
					<div>
						<h4>200+</h4>
						<p>Student Visas Successfully granted</p>
					</div>
					<div className='intro'>
						<h4>10</h4>
						<p>10 years combined experience in migration field </p>
					</div>
				</div>

				<div className='right_content_bottom'>
					<h4>Fast Agency</h4>
					<p>Proven innovative pathway</p>
				</div>
			</div>
		</StyledGrowth>
	);
}

const StyledGrowth = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 120px auto;
	max-width: 1150px;
	width: 100%;
	@media only screen and (max-width: 1200px) {
		padding: 0 6rem;
	}

	@media only screen and (max-width: 1050px) {
		flex-direction: column;
		align-items: center;
	}

	@media only screen and (max-width: 480px) {
		margin: 0 auto;
		align-items: flex-start;
		padding: 0 3rem;
	}

	.left {
		img {
			@media only screen and (max-width: 1200px) {
				width: 440px;
				height: 495px;
				object-fit: cover;
			}

			@media only screen and (max-width: 480px) {
				width: 288px;
				height: 317.33px;
				object-fit: cover;
			}
		}

		h2 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			line-height: 150%;
			/* or 60px */

			/* Grey/Light */

			color: #050505;
			margin-bottom: 60px;
			display: none;
			@media only screen and (max-width: 1050px) {
				display: flex;
			}
		}
	}

	.right {
		width: 443px;
		@media only screen and (max-width: 480px) {
			width: 90%;
		}
		.right_content {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: bold;
			font-size: 40px;
			line-height: 150%;
			/* or 60px */

			/* Grey/Light */

			color: #050505;
			margin-bottom: 60px;

			@media only screen and (max-width: 1050px) {
				font-size: 30px;
				display: none;
			}
		}

		.right_content_top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;
			@media only screen and (max-width: 1050px) {
				font-size: 20px;
				margin-top: 3rem;
			}

			@media only screen and (max-width: 480px) {
				flex-direction: column;
				align-items: flex-start;
			}

			.intro {
				margin-top: 3rem;
			}
		}

		.right_content_bottom {
		}

		h4 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 150%;
			/* or 45px */

			/* Brand/main */

			color: #183fc7;
			@media only screen and (max-width: 1050px) {
				font-size: 20px;
			}

			@media only screen and (max-width: 480px) {
				font-size: 32px;
			}
		}

		p {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;
			width: 155px;
			@media only screen and (max-width: 1050px) {
				font-size: 15px;
			}

			@media only screen and (max-width: 480px) {
				font-size: 17px;
				width: 90%;
			}
		}
	}
`;

export default Growth;
