import React from 'react';
import styled from 'styled-components';
import logo1 from '../images/logo1.svg';
import logo2 from '../images/logo2.svg';
import logo3 from '../images/logo3.svg';
import logo4 from '../images/logo4.svg';
import logo5 from '../images/logo5.svg';
import fulllogo from '../images/logointro.svg';

function Schoollogo() {
	return (
		<StyledSchoolLogo>
			<img className='main' src={logo1} alt='' />
			<img className='main' src={logo2} alt='' />
			<img className='main' src={logo3} alt='' />
			<img className='main' src={logo4} alt='' />
			<img className='main' src={logo5} alt='' />
			<img className='hide' src={fulllogo} alt='' />
		</StyledSchoolLogo>
	);
}

const StyledSchoolLogo = styled.div`
	display: flex;
	margin: 120px auto;
	max-width: 1150px;
	width: 100%;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 1050px) {
		flex-wrap: wrap;
		width: 80%;
	}

	.hide {
		display: none;

		@media only screen and (max-width: 480px) {
			display: flex;
		}
	}

	.main {
		margin: 0 30px;

		@media only screen and (max-width: 1200px) {
			margin: 0 0;
		}

		@media only screen and (max-width: 1100px) {
			width: 184px;
			height: 94px;
			object-fit: cover;
		}

		@media only screen and (max-width: 480px) {
			display: none;
		}

		:first-of-type {
			margin-right: 0;
		}
		:last-of-type {
			margin-right: 0;
		}
	}
`;

export default Schoollogo;
