import React from 'react';
import styled from 'styled-components';
import HowItWork from '../components/HowItWork';
import Partner from '../components/Partner';
import StudentDes from '../components/StudentDes';
import GraduateHero from '../components/GraduateHero';
import Reviews from '../components/Reviews';
import Faq from '../components/Faq';
import FormCom from '../components/FormCom';
import Footer from '../components/Footer';
import img from '../images/Pics and bg.svg';
import ScrollService from '../components/ScrollService';

function GraduateVisa({ active, setActive }) {
	return (
		<StyledGrad>
			<GraduateHero active={active} setActive={setActive} />
			<StudentDes title='For international students Australia offers a wonderful graduate visa program once studies are completed. This visa can be granted for anywhere between 1.5 to  4 years. For applicants that study in regional Australia longer graduate visas can  be granted. At OLA Migration we discuss the requirements of Graduate  Visas well before an applicant is eligible to ensure they can use their time  as a student visa holder in Australia to make the most of their  Graduate Visa opportunity.' />
			<HowItWork img={img} />
			<Partner />
			<Reviews />
			<Faq />
			<FormCom title='Drop us a message and see how we can help you!' />
			<ScrollService />
			<Footer />
		</StyledGrad>
	);
}

const StyledGrad = styled.div``;

export default GraduateVisa;
