import React from 'react';
import styled from 'styled-components';

function WhyChoose() {
	return (
		<StyledWhy>
			<h2 className='heading'>Why Choose Ola Migration </h2>
			<div className='content_list'>
				<li className='content'>
					<div className='content_number'>1</div>
					<p className='content_paragraph'>
						We are an Australian business, registered in Australia and bound by
						Australian Consumer laws.
					</p>
				</li>

				<li className='content'>
					<div className='content_number'>2</div>
					<p className='content_paragraph'>
						We have the necessary registration to provide Immigration Assistance
						(MARN 1800400). This means we are legally bound to give correct and
						up to date information and not overcharge for our services.
					</p>
				</li>

				<li className='content'>
					<div className='content_number'>3</div>
					<p className='content_paragraph'>
						We specialise in facilitating migration from Nigeria and Kenya to
						Australia. Most agencies will focus on students from other parts of
						the world, however, we have a genuine interest in getting Nigerian
						and Kenyan students to Australia.
					</p>
				</li>

				<li className='content'>
					<div className='content_number'>4</div>

					<p className='content_paragraph'>
						We can take care of every aspect of the application process from
						admission to visa grant and onto further applications including
						Australian Permanent Residency and Citizenship. This makes the
						process hassle-free for you and gives you peace of mind that every
						aspect of your journey to Australia is taken care of.
					</p>
				</li>
			</div>
		</StyledWhy>
	);
}

const StyledWhy = styled.div`
	background: rgba(233, 235, 238, 0.2);
	margin-top: 10rem;
	padding-bottom: 10rem;
	@media only screen and (max-width: 940px) {
		padding-left: 15px;
		padding-right: 15px;
	}
	.heading {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 5rem;
		line-height: 6rem;
		color: #04152f;
		text-align: center;
		padding: 7rem 0;

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 150%;
			/* or 42px */

			text-align: center;

			/* Black */

			color: #04152f;
		}
	}
	.content_list {
		/* width: 60%; */
		margin: 0 auto;
		max-width: 1150px;
		width: 100%;

		.content {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			margin-bottom: 3rem;
			/* padding: 0 60px; */

			.content_number {
				display: flex;
				align-items: center;
				justify-content: center;
				/* padding: 1rem 1rem; */
				width: 25px;
				height: 25px;
				border-radius: 50%;
				font-size: 1.4rem;
				background: #183fc7;
				color: #ffffff;
				/* object-fit: contain; */
				@media only screen and (max-width: 600px) {
					width: 35px;
				}

				@media only screen and (max-width: 480px) {
					width: 45px;
				}
			}

			.content_paragraph {
				margin-left: 6rem;
				margin-top: -0.5rem;
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: normal;
				font-size: 2rem;
				line-height: 150%;
				color: #04152f;
				width: 728px;

				@media only screen and (max-width: 600px) {
					font-family: SF Pro Text;
					font-style: normal;
					font-weight: normal;
					font-size: 17px;
					line-height: 150%;
					/* or 25px */

					/* Black */

					color: #04152f;
				}

				@media only screen and (max-width: 480px) {
					margin-left: 1rem;
				}
			}
		}
	}
`;
export default WhyChoose;
