import React, { useState } from 'react';
import styled from 'styled-components';
import Description from '../components/Description';
import Faq from '../components/Faq';
import Footer from '../components/Footer';
import Form from '../components/FormCom';
import Hero from '../components/Hero';
import MajorServices from '../components/MajorServices';
import Reviews from '../components/Reviews';
import WhyChoose from '../components/WhyChoose';

function HomePage({ active, setActive }) {
	const [loading, setloading] = useState(true);
	window.addEventListener('load', function () {
		setloading(false);
	});
	return (
		<StyledHome>
			<Hero active={active} setActive={setActive} />
			<Description />
			<MajorServices loading={loading} />
			<WhyChoose />
			<Reviews />
			<Faq loading={loading} />
			<Form title='Drop us a message and see how we can help you!' />
			<Footer />
		</StyledHome>
	);
}
const StyledHome = styled.div`
	width: 100%;
`;

export default HomePage;
