import React from 'react';
import styled from 'styled-components';
import trans from '../images/Kangarro.svg';
import pic from '../images/joined.png';
import Hamburger from './Hamburger';

import Nav from './Nav';

function AboutHero({ active, setActive }) {
	return (
		<StyledHero>
			<Nav active={active} setActive={setActive} />
			<div className='wrapper'>
				<div className='left'>
					<h4>OLAMIGRATION</h4>

					<h2>
						We are committed to <br />
						facilitating migration
						<br /> from Africa to Australia
					</h2>

					<p>
						Our mission is to facilitate migration to
						<br /> Australia from the African continent; starting
						<br /> with Nigeria and Kenya. We know it is <br /> difficult for
						individuals in these regions to
						<br /> access quality representation and we seek
						<br /> to fill that gap.
					</p>
				</div>

				<div className='right'>
					<img src={pic} alt='' />
				</div>
			</div>

			<div className='kangaroo'>
				<img src={trans} alt='' />
			</div>
			<Hamburger active={active} setActive={setActive} />
		</StyledHero>
	);
}

const StyledHero = styled.div`
	background: #001995;
	position: relative;
	height: 725px;
	@media only screen and (max-width: 1050px) {
		height: 797px;
	}

	.kangaroo {
		position: absolute;
		top: 68px;
		right: 371.34px;
		left: 512px;
		bottom: 108.66px;
		@media only screen and (max-width: 1050px) {
			top: 68px;
			right: 571.34px;
			left: 312px;
			bottom: 108.66px;
		}

		@media only screen and (max-width: 850px) {
			top: 68px;
			right: 571.34px;
			left: 112px;
			bottom: 108.66px;
		}

		@media only screen and (max-width: 650px) {
			top: 500px;
			right: 32.26px;
			left: 66px;
			bottom: 61.66px;
		}

		img {
			@media only screen and (max-width: 650px) {
				width: 221.74px;
				height: 235.34px;
			}
		}
	}

	.wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1150px;
		width: 100%;
		margin: 120px auto;
		/* position: absolute;
		top: 20%;
		left: 0; */

		@media only screen and (max-width: 1200px) {
			padding: 0 6rem;
		}
		@media only screen and (max-width: 1050px) {
			flex-direction: column;
			align-items: center;
		}
		@media only screen and (max-width: 480px) {
			flex-direction: column;
			align-items: flex-start;
			padding: 0 3rem;
		}

		.left {
			h4 {
				font-family: Catamaran;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 23px;
				color: #ffffff;
				margin-bottom: 30px;

				@media only screen and (max-width: 1050px) {
					font-family: SF Pro Text;
					font-style: normal;
					font-weight: 800;
					font-size: 12px;
					line-height: 14px;

					/* White */

					color: #ffffff;
				}
			}
			h2 {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: bold;
				font-size: 40px;
				line-height: 150%;
				color: #ffffff;
				margin-bottom: 30px;
				/* width: 439px; */

				@media only screen and (max-width: 1050px) {
					font-family: SF Pro Display;
					font-style: normal;
					font-weight: 600;
					font-size: 28px;
					line-height: 150%;
					/* or 42px */

					/* White */

					color: #ffffff;
				}
			}

			p {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 150%;
				color: #ffffff;
				margin-bottom: 30px;
				/* width: 439px; */

				@media only screen and (max-width: 1050px) {
					font-family: SF Pro Text;
					font-style: normal;
					font-weight: normal;
					font-size: 17px;
					line-height: 150%;
					/* or 25px */

					/* White */

					color: #ffffff;
				}
			}
		}

		.right {
			img {
				@media only screen and (max-width: 1200px) {
					width: 440px;
					height: 495px;
					object-fit: cover;
				}
				@media only screen and (max-width: 1050px) {
					width: 288px;
					height: 317.33px;
					object-fit: cover;
				}
			}
		}
	}
`;

export default AboutHero;
