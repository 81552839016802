import React from 'react';
import styled, { keyframes } from 'styled-components';
import Nav from './Nav';
import HeroImage from '../images/HeroImage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Hamburger from './Hamburger';

function Hero({ active, setActive }) {
	const navigate = useNavigate();
	return (
		<HeroStyle>
			<Nav active={active} setActive={setActive} />

			<StyledContent>
				<h2 className='hero_h2'>
					Your migration to Australia
					<br className='break' /> starts here.
				</h2>

				<p className='hero_p'>
					Committed to making your move to Australia a reality;
					<br className='break' /> With you all the way as you journey to
					Australia.
				</p>

				<button onClick={() => navigate('/contactus')} className='hero_button'>
					Get In Touch
				</button>
			</StyledContent>

			<Hamburger active={active} setActive={setActive} />

			<FontAwesomeIcon className='arrow' icon={faArrowCircleDown} size='4x' />
		</HeroStyle>
	);
}

const Bounce = keyframes`
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-50px);} 
   60% {transform: translateY(-25px);} 
`;
const HeroStyle = styled.div`
	width: 100%;
	height: 100vh;
	background-image: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		url(${HeroImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;

	.arrow {
		animation: ${Bounce} 1s linear forwards infinite;
		color: #ffffff;
		position: absolute;
		top: 90%;
		left: 49%;
		transform: translate(-50%, -50%);
	}
`;

const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;

	@media only screen and (max-width: 480px) {
		justify-content: flex-start;
		align-items: flex-start;
		top: 35%;
		left: 16px;
		transform: none;
		width: 90%;
	}

	.hero_h2 {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 800;
		font-size: 6rem;
		line-height: 7.2rem;
		text-align: center;
		color: #ffffff;
		/* width: 92rem; */
		width: 920px;

		@media only screen and (max-width: 940px) {
			width: 80%;
		}

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 800;
			font-size: 34px;
			line-height: 41px;
			width: 95%;
			color: #ffffff;
		}
		@media only screen and (max-width: 480px) {
			/* width: 100%; */
			text-align: left;
		}

		.break {
			@media only screen and (max-width: 600px) {
				display: none;
			}
		}
	}

	.hero_p {
		font-family: SF Pro Text;
		font-style: normal;
		font-weight: normal;
		font-size: 2.4rem;
		line-height: 150%;
		text-align: center;
		color: #ffffff;
		margin: 2rem 0 5rem 0;
		/* width: 52rem; */

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			color: #ffffff;
		}
		@media only screen and (max-width: 480px) {
			/* width: 100%; */
			text-align: left;
		}

		.break {
			@media only screen and (max-width: 600px) {
				display: none;
			}
		}
	}

	.hero_button {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 1.8rem;
		display: flex;
		align-items: center;
		text-align: center;
		color: #ffffff;
		padding: 1.4rem 4.3rem;
		width: 17rem;
		height: 4.6rem;
		background: #183fc7;
		border-radius: 0.5rem;
		outline: none;
		border: none;
		cursor: pointer;
	}
`;

export default Hero;
