import { createGlobalStyle } from 'styled-components';
import Prodisplay from '../fonts/SFPRODISPLAYREGULAR.OTF';
import Protext from '../fonts/SF-Pro-Text-Regular.otf';

const GlobalStyles = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
  font-size: 10px;
  color: #4F5665;
   /* overflow-y: auto; */

   @media only screen and (max-width: 1200px) {
			font-size: 8px;
		}
  &::-webkit-scrollbar{
            width: .9rem;
           display: none;   
        }
    &::-webkit-scrollbar-thumb{
            background-color:  #000000;
        }
    &::-webkit-scrollbar-track {
    background: white;
     
        }
      

      
}
        
}
body{
    background-color: #ffffff;
    font-family: 'Rubik', sans-serif;
    overflow-x: hidden !important;

}
a{
  text-decoration: none;
  font-size: 1.1rem;
  color: #4F5665;
}
ul {
  list-style: none;
}
li {
  list-style: none;
}


@font-face {
  font-family: 'SF Pro Display';
  src: url(${Prodisplay}) format('woff2'), 
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(${Protext}) format('woff2'),
}
  
`;

export default GlobalStyles;
