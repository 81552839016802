import React from 'react';
import styled from 'styled-components';

function StudentDes({ title }) {
	return (
		<StyledDes>
			<p>{title}</p>
		</StyledDes>
	);
}
const StyledDes = styled.div`
	margin: 60px auto;
	max-width: 1150px;
	width: 100%;

	@media only screen and (max-width: 1200px) {
		padding: 0 15px;
	}
	p {
		font-family: SF Pro Text;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 150%;
		/* or 30px */

		text-align: center;

		/* Black */
		margin: 0 auto;
		max-width: 920px;
		color: #04152f;

		@media only screen and (max-width: 600px) {
			font-size: 17px;
		}
	}
`;

export default StudentDes;
