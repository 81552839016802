import React from 'react';
import styled from 'styled-components';
import logo from '../images/fullLogo.svg';
import { Link } from 'react-router-dom';
import trans from '../images/Kangarro.svg';
import IG from '../images/instagram.svg';
import IN from '../images/icons8-linkedin.svg';
import FB from '../images/icons8-facebook-circled.svg';
import { useNavigate } from 'react-router-dom';

function Footer() {
	const navigate = useNavigate();

	const style = {
		fontFamily: 'SF Pro Display',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '1.7rem',
		lineHeight: '150%',
		color: '#ffffff',
	};
	return (
		<StyledFooter>
			<div className='wrapper'>
				<div className='kangaroo'>
					<img src={trans} alt='' />
				</div>
				<div className='top'>
					<div onClick={() => navigate('/')} className='imagelogo'>
						<img src={logo} alt='' />
					</div>
					<div className='list_wrappers'>
						<div className='list'>
							<h2>Services</h2>
							<li>
								<Link to='/studentvisa' style={style}>
									Student Visas
								</Link>
							</li>
							<li>
								<Link to='/graduatevisa' style={style}>
									Gradutate Visas
								</Link>
							</li>
							<li>
								<Link to='/partnervisa' style={style}>
									Partner Visas
								</Link>
							</li>
							{/* <li>
								<Link to='/notfound' style={style}>
									Skilled Visas
								</Link>
							</li>
							<li>
								<Link to='/notfound' style={style}>
									Employer Sponsored Visas
								</Link>
							</li>
							<li>
								<Link to='/notfound' style={style}>
									Other Migration Visas
								</Link>
							</li> */}
						</div>

						<div className='list'>
							<h2>Company</h2>
							<li>
								<Link to='/aboutus' style={style}>
									About Us{' '}
								</Link>
							</li>
							<li>
								<Link to='/contactus' style={style}>
									Contact Us{' '}
								</Link>
							</li>
						</div>
					</div>
				</div>

				<div className='bottom'>
					<StyledFooterList>
						<StyledItemlist>
							<StyledItemSocialLink>
								<a href='https://instagram.com/jasminesallis?igshid=YmMyMTA2M2Y='>
									<img src={IG} alt='' />
								</a>{' '}
							</StyledItemSocialLink>
						</StyledItemlist>

						<StyledItemlist>
							<StyledItemSocialLink>
								<a href='https://www.linkedin.com/in/jasmine-sallis-014a0a184'>
									<img src={IN} alt='' />
								</a>
							</StyledItemSocialLink>
						</StyledItemlist>

						<StyledItemlist>
							<StyledItemSocialLink>
								<a href='https://www.facebook.com/profile.php?id=100061003705093'>
									<img src={FB} alt='' />
								</a>
							</StyledItemSocialLink>
						</StyledItemlist>
					</StyledFooterList>
					<p>&copy; 2021 Ola Migration Ltd. All Rights Reserved.</p>
				</div>
			</div>
		</StyledFooter>
	);
}

const StyledFooter = styled.div`
	background: #04152f;
	position: relative;
	padding: 8.5rem 0;
	@media only screen and (max-width: 1200px) {
		padding: 8.5rem 15px;
	}
	.kangaroo {
		position: absolute;
		top: 14rem;
		right: 5rem;
		/* @media only screen and (max-width: 1200px) {
			padding: 8.5rem 15px;
		} */

		img {
			@media only screen and (max-width: 1200px) {
				width: 436px;
				height: 436px;
				object-fit: cover;
			}
		}
	}
	.wrapper {
		/* width: 75%; */
		margin: 0 auto;
		max-width: 1150px;
		width: 100%;
		.top {
			display: flex;
			align-items: flex-start;
			border-bottom: 2px solid #c3c9d0;
			padding-bottom: 9rem;

			@media only screen and (max-width: 750px) {
				justify-content: space-between;
				flex-direction: column;
			}
			/* @media only screen and (max-width: 600px) {
				
			} */

			.imagelogo {
				cursor: pointer;
			}

			.list_wrappers {
				display: flex;
				align-items: flex-start;
				width: 70%;

				@media only screen and (max-width: 750px) {
					justify-content: space-between;
					margin-top: 4rem;
				}
			}

			.list {
				margin-left: 15rem;

				@media only screen and (max-width: 750px) {
					margin-left: 0;
				}
			}
			h2 {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: 600;
				font-size: 3rem;
				line-height: 150%;
				color: #ffffff;
				margin-bottom: 1.7rem;
			}

			li {
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: normal;
				font-size: 2rem;
				line-height: 150%;
				color: #ffffff;
				margin-bottom: 1.6rem;
			}
		}

		.bottom {
			p {
				font-family: SF Pro Text;
				font-style: normal;
				font-weight: normal;
				font-size: 1.7rem;
				line-height: 2rem;
				color: #ffffff;
				margin-top: 3rem;
			}
		}
	}
`;

const StyledFooterList = styled.div`
	display: flex;
	align-items: center;
	margin-top: 2rem;
`;
const StyledItemlist = styled.div`
	margin: 1rem;

	&:first-of-type {
		margin-left: 0;
	}
`;
const StyledItemSocialLink = styled.div``;

export default Footer;
