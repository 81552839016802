import React from 'react';
import styled from 'styled-components';

function HowItWork({ img }) {
	return (
		<StyledHow>
			<div className='left'>
				<h3 className='right_h3'>How it works</h3>
				<img src={img} alt='' />
			</div>

			<div className='right'>
				<h3 className='right_h3'>How it works</h3>
				<p className='right_p1'>
					Our first step is to get to know you and the reason you are interested
					in applying for an Australian Student Visa.
					<br /> <br /> From here we can then suggest which courses will be best
					suited to you and your goals and suggest some education institutions.
					We will advise which documents we need at each stage of the process
					and will make this as simple as possible.
					<br /> <br /> You will always be able to speak directly with our
					Registered Migration Agent in Australia and support staff in Nigeria
					and Kenya. We are a small team with significant experience of the
					education and immigration system in Australia. We work together to
					make sure your dream of moving to Australia is achieved.
					<br /> <br />
					Find out what evidence is required for a Student Visa to Australia by
					downloading our{' '}
					<a href='/' className='linker'>
						FREE Student Visa Document Checklist
					</a>
				</p>
			</div>
		</StyledHow>
	);
}

const StyledHow = styled.div`
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	padding: 163px 0px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: center;
	}
	@media only screen and (max-width: 600px) {
		padding: 60px 0px 0px 0px;
	}
	.left {
		margin-bottom: 6rem;

		.right_h3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;
			display: none;
			@media only screen and (max-width: 1200px) {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			@media only screen and (max-width: 600px) {
				font-size: 32px;
				justify-content: flex-start;
				align-items: flex-start;
				margin-left: -30px;
			}
		}
		img {
			@media only screen and (max-width: 600px) {
				width: 400px;
				height: 400px;
				object-fit: cover;
			}

			@media only screen and (max-width: 430px) {
				width: 265.81px;
				height: 293.23px;
				object-fit: cover;
			}
		}
	}

	.right {
		margin-left: 72px;

		@media only screen and (max-width: 1200px) {
			margin-left: 0;
			padding: 0 15px;
		}
		.right_h3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;
			@media only screen and (max-width: 1200px) {
				display: none;
			}

			@media only screen and (max-width: 600px) {
				font-size: 32px;
			}
		}

		.right_p1 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;

			@media only screen and (max-width: 600px) {
				font-size: 17px;
			}
		}

		.right_p2 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			color: #000000;
		}

		.linker {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			color: #3657ce;
		}
	}
`;

export default HowItWork;
