import { motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import plus from '../images/plus.png';
// import times from '../images/times.png';
import times from '../images/close-outline.svg';

const Toggle = ({ children, title }) => {
	const [toggle, setToggle] = useState(false);
	return (
		<motion.div layout className='question' onClick={() => setToggle(!toggle)}>
			<Styledtoggle>
				<motion.h4 layout>{title}</motion.h4>
				{/* <FontAwesomeIcon layout icon={toggle ? faTimes : faPlus} size="3x" /> */}
				<motion.img src={toggle ? times : plus} alt='plus/times' />
			</Styledtoggle>

			{toggle ? children : ''}
			<div className='faq-line'></div>
		</motion.div>
	);
};

const Styledtoggle = styled(motion.div)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 1rem;
	cursor: pointer;
	img {
		width: 3rem;
		height: 3rem;
	}
	h4 {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: normal;
		font-size: 2.4rem;
		line-height: 150%;
		color: #04152f;
		width: 502px;
	}
`;
export default Toggle;
