import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function Description() {
	const navigate = useNavigate();

	return (
		<StyledDes>
			<h1 className='header'>
				We are making migration to Australia easy for <br />
				Nigerians and Kenyans{' '}
			</h1>
			<p className='paragraph_main'>
				At Ola migration we have decided to specialise. Unlike other migration
				consultancies we choose to specialise in the area of the world we
				service rather than the type of visas we process. We have chosen two
				regions that we feel are underrepresented, often have more difficulty
				obtaining Australian visas and that our Principle Migration Agent and
				team have a personal connection to - Nigeria and Kenya.
			</p>
			<p className='paragraph_link' onClick={() => navigate('/aboutus')}>
				Learn more about us &#8594;
			</p>
		</StyledDes>
	);
}
const StyledDes = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	/* width: 75%; */
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	margin: 120px auto;

	@media only screen and (max-width: 1200px) {
		padding: 0 15px;
	}
	.header {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		line-height: 60px;
		text-align: center;
		color: #04152f;
		margin-bottom: 60px;

		@media only screen and (max-width: 1200px) {
			width: 80%;
		}

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 150%;
			/* or 42px */

			/* Black */

			color: #04152f;
		}
	}

	.paragraph_main {
		font-family: SF pro Text;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 150%;
		text-align: center;
		color: #04152f;
		margin: 2rem 0 1.7rem 0;
		width: 73rem;
		@media only screen and (max-width: 1200px) {
			width: 100%;
		}

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 150%;
			/* or 25px */

			/* Black */

			color: #04152f;
		}
	}

	.paragraph_link {
		font-family: SF Pro Text;
		font-style: normal;
		font-weight: normal;
		font-size: 1.7rem;
		line-height: 2rem;
		display: flex;
		align-items: center;
		text-align: center;
		color: #183fc7;
		cursor: pointer;
		@media only screen and (max-width: 600px) {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 20px;
			display: flex;
			align-items: center;
			text-align: center;

			/* Brand/main */

			color: #183fc7;
		}
	}
`;
export default Description;
