import React from 'react';
import styled, { keyframes } from 'styled-components';
import Nav from './Nav';
import studentH from '../images/heroforstudentvisa.png';
import Hamburger from './Hamburger';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

function StudentHero({ active, setActive }) {
	return (
		<HeroStyle>
			<Nav active={active} setActive={setActive} />

			<StyledContent>
				<h2 className='hero_h2'>Australian Student Visas</h2>
			</StyledContent>

			{/* <FontAwesomeIcon className='arrow' icon={faArrowCircleDown} size='4x' /> */}
			<Hamburger active={active} setActive={setActive} />
		</HeroStyle>
	);
}

const Bounce = keyframes`
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-50px);} 
   60% {transform: translateY(-25px);} 
`;
const HeroStyle = styled.div`
	width: 100%;
	height: 70vh;
	background-image: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.7),
			rgba(0, 0, 0, 0.7)
		),
		url(${studentH});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;

	.arrow {
		animation: ${Bounce} 1s linear forwards infinite;
		color: #ffffff;
		position: absolute;
		top: 90%;
		left: 49%;
		transform: translate(-50%, -50%);
	}
`;

const StyledContent = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media only screen and (max-width: 600px) {
		justify-content: flex-start;
		top: 50%;
		left: 0;
		transform: none;
		padding: 0 6rem;
	}

	@media only screen and (max-width: 480px) {
		padding: 0 3rem;
	}

	h2 {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 800;
		font-size: 60px;
		line-height: 73px;
		/* identical to box height */

		display: flex;

		/* White */

		color: #ffffff;
		text-align: center;

		@media only screen and (max-width: 600px) {
			font-size: 44px;
			line-height: 41px;
			text-align: left;
		}
	}
`;

export default StudentHero;
