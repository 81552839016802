import React from 'react';
import styled from 'styled-components';
import Partner from '../components/Partner';
import StudentDes from '../components/StudentDes';
import Reviews from '../components/Reviews';
import Faq from '../components/Faq';
import FormCom from '../components/FormCom';
import Footer from '../components/Footer';
import HowItWorkII from '../components/HowItWorkII';
import PartnerHero from '../components/PartnerHero';
import HowItWorkIII from '../components/HowItWorkIII';
import ButtonDes from '../components/ButtonDes';

function PartnerVisa({ active, setActive }) {
	return (
		<StyledPartner>
			<PartnerHero active={active} setActive={setActive} />
			<StudentDes
				title='A Partner Visa allows an Australian Citizen or Permanent Resident to sponsor their spouse or de facto partner to live with them in Australia. The Partner Visa process occurs in two stages with a temporary visa issued first and a permanent visa after 2 years if the relationship is continuing.

'
			/>
			<HowItWorkII />
			<HowItWorkIII />
			<ButtonDes />
			<Partner />
			<Reviews />
			<Faq />
			<FormCom title='Drop us a message and see how we can help you!' />
			<Footer />
		</StyledPartner>
	);
}
const StyledPartner = styled.div``;

export default PartnerVisa;
