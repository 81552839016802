import React from 'react';
import styled from 'styled-components';
import faqpicture from '../images/use.png';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';
// import Skeleton from '@mui/material/Skeleton';

function Faq({ loading }) {
	return (
		<StyledFaq>
			<h2 className='heading'>Frequenty asked Questions</h2>
			<div className='flex_faq'>
				<div className='flex_faq_list'>
					<AnimateSharedLayout>
						<Toggle title='What course should I study to get Australian Permanent Residency?'>
							<div className='answer'>
								<p>
									This is a common question that we get asked every day at Ola
									Migration. There are some courses that are highly likely to
									give students a good chance of obtaining Permanent Residency
									such as Nursing. Some courses that can provide Permanent
									Residency options are surprising such as Commercial Cookery
									and Motor Mechanics. The best course for you to study,
									however, will depend on your individual circumstances
									including any previous studies, work experience and career
									aspirations.
								</p>
							</div>
						</Toggle>
						<Toggle title='Can I study the cheapest course just to get the visa?'>
							<div className='answer'>
								There are definitely cheaper courses to study in Australia that
								are sufficient to be granted an Australian Student Visa. Many
								business courses at Diploma level for example are cheaper
								options. These courses may be financially appealing, however,
								they will not generally provide further options to remain in
								Australia and are often insufficient to allow graduates to
								obtain a Graduate Visa following completion of their studies.
								This Graduate Visa allows up to 4 additional years of living and
								working in Australia with no restrictions (full work rights!).
							</div>
						</Toggle>
						<Toggle title='Can I work while studying in Australia?'>
							<div className='answer'>
								<p>
									Yes. International Students in Australia can work up to 40
									hours per week. With a living wage of $25 an hour this
									allowance often enables international students to cover their
									living costs while studying in Australia.
								</p>
							</div>
						</Toggle>
						<Toggle title='What are the conditions of holding a Student Visa?'>
							<div className='answer'>
								<p>
									As a holder of an Australian Student Visa you must maintain
									adequate health insurance, you must not work more than 40
									hours a fortnight and you must continue to be enrolled in your
									studies. It is important to know the conditions on your visa
									to avoid facing a visa cancellation. At Ola Migration we
									regularly check in with our students and make sure they are
									aware of the conditions of their visa. We are also always
									available to answer questions from our clients that may be
									confused about any conditions they are required to meet.
								</p>
							</div>
						</Toggle>
						<Toggle title='Are student visas for Australia easy to get?'>
							<div className='answer'>
								<p>
									There is no direct answer to this question. Student visas are
									one of the easier visas to be granted from Australia if all
									the requirements are met. The Australian immigration system,
									however, is one of the most difficult to navigate. The rules
									change regularly and there are a lot of hoops to jump through.
									Using a Registered Migration Agent, however, can ensure you
									meet all the criteria to give you application the best chance
									of success. Legally speaking if an applicant meets all the
									requirements the visa must be granted.
								</p>
							</div>
						</Toggle>
						<Toggle title='How can I prove to the Australian authorities that I am a ‘genuine temporary entrant’?'>
							<div className='answer'>
								<p>
									There are a number of factors that a decision maker will
									consider when assessing this criterion. To meet the Genuine
									Temporary Entrant criterion of Australian Student Visas it is
									imperative to show substantial ties to your country of origin
									including familial and financial ties. It can be useful to
									show employment opportunities and future plans and to indicate
									how particular studies will help a student to achieve these.
									Essentially it is important to prove to the decision maker
									that you are coming to Australia for the purpose of study and
									not for another purpose such as work. At Ola Migration we have
									experience in preparing submissions that support Student Visa
									applications to show the Australian Government that they are
									genuine temporary entrants.
								</p>
							</div>
						</Toggle>

						<Toggle title='Can I bring my family with my while I study in Australia?'>
							<div className='answer'>
								<p>
									Yes you can bring any dependents with you to Australia
									including a spouse and dependent children. Your spouse will be
									able to work in Australia while you study and the children
									will be able to attend primary or secondary school and access
									other services.
								</p>
							</div>
						</Toggle>

						<Toggle title='Why is Australia a popular choice for international students?'>
							<div className='answer'>
								<p>
									Australia has an excellent education system with
									qualifications recognised internationally. However, the
									popularity of studying in Australia lies most significantly in
									the opportunities provided to international graduates post
									study. The Graduate Visa option provides full work rights to
									international graduates who have studied for at least 2 years
									in Australia. The Graduate Visa has recently been extended for
									international graduates who studies in regional areas of
									Australia meaning that a visa of up to 4 years can be obtained
									with full work rights. There are specific requirements that
									must be met while holding a student visa in order to obtain a
									Graduate Visa after studies. Our team at Ola Migration will
									make sure you are aware of this from before you even enroll in
									a course. There is nothing worse than missing out on the
									Graduate Visa option from Australia.
								</p>
							</div>
						</Toggle>

						<Toggle title='Why are work rights in Australia so appealing?'>
							<div className='answer'>
								<p>
									Working conditions in Australia are extremely good, pay is
									high and work is quite easy to find. Australia relies heavily
									on an international work force to fill many gaps in the
									working population. Workers are protected from exploitation in
									Australia including international students through Australias
									work laws. In addition there is a tax free threshold of
									$18,000 which means the first $18,000 earned is tax free. You
									can expect to be earning at least $30,000 a year as an
									International Student.
								</p>
							</div>
						</Toggle>

						<Toggle title='What sort of work can I find easily in Australia while I am a student?'>
							<div className='answer'>
								<p>
									There is so much work in Australia and students fulfill a
									large proportion of the workforce. Popular employment for
									international students includes working in Aged Care, Customer
									Service, Cleaning, Construction, Delivery driving,
									Cooks/Chefs, Restaurant workers and more. For individuals who
									already have qualifications there are opportunities in IT,
									Engineering, Health Care and Child Care to name a few.
									Occupations that in many countries are significantly underpaid
									are well paid in Australia, including those mentioned above.
								</p>
							</div>
						</Toggle>
					</AnimateSharedLayout>
				</div>

				<div className='flex_img'>
					<img src={faqpicture} loading='lazy' alt='' />
				</div>
			</div>
		</StyledFaq>
	);
}

const StyledFaq = styled.div`
	/* margin: 0 auto; */
	max-width: 1150px;
	width: 100%;
	margin: 20rem auto 10rem auto;
	@media only screen and (max-width: 1200px) {
		padding: 0 15px;
	}

	.heading {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 5rem;
		line-height: 6rem;
		color: #050505;
	}

	.flex_faq {
		display: flex;
		align-items: flex-start;
		margin-top: 3rem;

		.flex_faq_list {
			/* flex: 1; */
			width: 520px;

			@media only screen and (max-width: 1200px) {
				width: 100%;
				justify-content: center;
			}

			.faq-line {
				background: #c3c9d0;
				height: 0.2rem;
				margin: 2rem 0rem;
				width: 100%;
			}
			.question {
				/* padding: 0.3rem 0rem; */
				cursor: pointer;
				width: 502px;
				font-family: SF Pro Text;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 150%;
				/* or 36px */

				/* Black */

				color: #04152f;

				@media only screen and (max-width: 1200px) {
					width: auto;
				}
			}
			.answer {
				padding: 0.5rem 0rem;
				font-family: SF Pro Display;
				font-style: normal;
				font-weight: normal;
				font-size: 1.7rem;
				line-height: 3rem;
				color: #050505;
				p {
					padding: 1rem 0rem;
				}
			}
		}

		.flex_img {
			/* flex: 1; */
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 2rem;
			margin-left: 165px;
			height: auto;

			/* img {
				width: 60.9rem;
				height: 55rem;
				object-fit: cover;
				object-position: bottom;
			} */

			@media (max-width: 1400px) {
				margin-left: 0px;
			}
			@media only screen and (max-width: 1200px) {
				display: none;
			}
		}
	}
`;
export default Faq;
