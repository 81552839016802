import React from 'react';
import styled from 'styled-components';
import howe from '../images/Pics and bg.png';

function HowItWorkII() {
	return (
		<StyledHow>
			<div className='left'>
				<h3 className='right_h1'>Am I Eligible?</h3>

				<img src={howe} alt='' />
			</div>

			<div className='right'>
				<h3 className='right_h1'>Am I Eligible?</h3>
				<p className='right_p'>
					The relationship itself is not enough to ensure success with a partner
					visa as there are some key criteria to be met including:
				</p>
				<li className='right_li'>
					<div className='right_circle'></div>
					<p className='right_lip'>
						That the couple are in a mutually exclusive relationship
					</p>
				</li>
				<li className='right_li'>
					<div className='right_circle'></div>
					<p className='right_lip'>
						That the relationship is genuine and continuing
					</p>
				</li>
				<li className='right_li'>
					<div className='right_circle'></div>
					<p className='right_lip'>
						That the couple are living together, or apart only temporarily
					</p>
				</li>
			</div>
		</StyledHow>
	);
}

const StyledHow = styled.div`
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
	padding: 80px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: center;
	}
	@media only screen and (max-width: 600px) {
		padding: 60px 0px 0px 0px;
	}

	.left {
		.right_h1 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;
			display: none;
			@media only screen and (max-width: 1200px) {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			@media only screen and (max-width: 600px) {
				font-size: 32px;
				justify-content: flex-start;
				align-items: flex-start;
				/* margin-left: -30px; */
			}
		}
		img {
			@media only screen and (max-width: 600px) {
				width: 400px;
				height: 400px;
				object-fit: cover;
				margin-bottom: 3rem;
			}

			@media only screen and (max-width: 430px) {
				width: 265.81px;
				height: 293.23px;
				object-fit: cover;
			}
		}
	}

	.right {
		margin-left: 72px;

		@media only screen and (max-width: 1200px) {
			margin-left: 0;
			padding: 0 15px;
		}

		.right_h1 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 50px;
			line-height: 60px;

			/* Black */

			color: #04152f;
			margin-bottom: 30px;

			@media only screen and (max-width: 1200px) {
				display: none;
			}

			@media only screen and (max-width: 600px) {
				font-size: 32px;
			}
		}

		.right_p {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;
			margin-bottom: 30px;

			@media only screen and (max-width: 600px) {
				font-size: 17px;
			}
		}

		.right_li {
			list-style: none;
			display: flex;
			align-items: center;
			margin-bottom: 30px;
		}

		.right_circle {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background: #183fc7;
		}

		.right_lip {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 150%;
			/* or 30px */

			/* Black */

			color: #04152f;
			margin-left: 20px;
			@media only screen and (max-width: 600px) {
				font-size: 17px;
			}
		}
	}
`;

export default HowItWorkII;
