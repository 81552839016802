import React from 'react';
import styled from 'styled-components';
import trans from '../images/kang.svg';
import Nav from './Nav';
import Hamburger from './Hamburger';

function ContactHero({ active, setActive }) {
	return (
		<StyledHero>
			<Nav active={active} setActive={setActive} />

			<div className='wrapper'>
				<h3>Contact Us</h3>
				<p>Please complete the form provided to get in touch with us.</p>
			</div>

			<div className='kangaroo'>
				<img src={trans} alt='' />
			</div>
			<Hamburger active={active} setActive={setActive} />
		</StyledHero>
	);
}

const StyledHero = styled.div`
	background: #001995;
	position: relative;
	height: 267px;

	.kangaroo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.wrapper {
		margin-top: 40px;
		h3 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 800;
			font-size: 60px;
			line-height: 73px;
			/* identical to box height */

			text-align: center;

			/* White */

			color: #ffffff;

			@media only screen and (max-width: 480px) {
				font-size: 28px;
			}
		}

		p {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 150%;
			/* or 25px */

			text-align: center;

			/* White */

			color: #ffffff;

			@media only screen and (max-width: 480px) {
				font-size: 17px;
			}
		}
	}
`;

export default ContactHero;
