import React from 'react';
import styled from 'styled-components';
import HowItWork from '../components/HowItWork';
import Partner from '../components/Partner';
import StudentDes from '../components/StudentDes';
import StudentHero from '../components/StudentHero';
import Reviews from '../components/Reviews';
import Faq from '../components/Faq';
import FormCom from '../components/FormCom';
import Footer from '../components/Footer';
import img from '../images/Pics and bg.svg';

function StudentVisa({ active, setActive }) {
	return (
		<StyledStudent>
			<StudentHero active={active} setActive={setActive} />
			<StudentDes
				title='At OLA Migration we take care of your student visa from the first step to the final step. Starting with gaining admission into your chosen course of study and institution all the way to being granted the visa, complying with visa conditions and preparing for subsequent visa applications. Everything is taken care of including health  insurance, institution admissions and visa applications. Student visas are often a first step into Australia and our team makesure we cater for those of you  coming to Australia with very little knowledge of the visa system,  requirements and compliance issues that can arise. 
'
			/>
			<HowItWork img={img} />
			<Partner />
			<Reviews />
			<Faq />
			<FormCom title='Drop us a message and see how we can help you!' />
			<Footer />
		</StyledStudent>
	);
}

const StyledStudent = styled.div``;

export default StudentVisa;
