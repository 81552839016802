import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonial.css';
import { Avatar } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import styled from 'styled-components';
import NigeriaFlag from '../images/nigeriawave.png';
import ukflag from '../images/united-kingdom-flag-icon.svg';
import vietnam from '../images/vietname.png';
import philipines from '../images/jp-draws-Philippines-Flag.png';

const PreviousBtn = (props) => {
	console.log(props);
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ArrowBackIos style={{ color: 'gray', fontSize: '35px' }} />
		</div>
	);
};
const NextBtn = (props) => {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ArrowForwardIos style={{ color: 'gray', fontSize: '35px' }} />
		</div>
	);
};

const styleP = {
	fontFamily: 'SF Pro Display',
	fontStyle: 'normal',
	fontWeight: 'normal',
	fontSize: '20px',
	lineHeight: '150%',
	textAlign: 'center',
	color: '#04152F',
	// width: '286px',
};

const styleF = {
	fontFamily: 'SF Pro Display',
	fontStyle: 'normal',
	fontWeight: 'normal',
	fontSize: '2.4rem',
	lineHeight: '150%',
	textAlign: 'center',
	color: '#04152F',
	marginTop: 30,
};

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
};

function Reviews() {
	return (
		<StyledReview>
			<h1 className='reviewHeading'>What Our Clients Are Saying </h1>
			<div
				className='testimonial'
				style={{
					display: 'flex',
					justifyContent: 'center',
					margin: '3rem 0',
				}}>
				<div className='slider'>
					<Slider
						prevArrow={<PreviousBtn />}
						nextArrow={<NextBtn />}
						dots
						autoplay>
						<Card
							img={NigeriaFlag}
							content='	
				Jasmine helped me with tricky situation. What she told me was true and I can see she is 
clever and knows all the process. I wont go to anyone else now.
			'
							title='Ibrahim K'
						/>
						<Card
							img={NigeriaFlag}
							content='	
				Jasmine made my visa hassle free. The best part was it was granted much quicker than 
the processing time for Partner Visas and I was in Australia a year earlier than I thought I 
would be.
			'
							title='Olanrewaju Dopamu'
						/>
						<Card
							img={ukflag}
							content='	
				Thank you for sorting my Partner Visa. It was such a relief to have this handled by 
Jasmine. If I had to do it myself I wouldnt have known where to begin. Highly 
recommend.
			'
							title='Sandy'
						/>
						<Card
							img={vietnam}
							content='	
			Hi Jasmine. I just wanted to let you know our Partner Visa was approved and Kim is now back in Australia 🇦🇺 We just wanted to say Thank you so much for all your help during this process you will never know how much it means for us to have your Help and Support during this hard time in our lives. Kim and I just wanted to say thanks again for everything you did for us. Hope you’re well too
			'
							title='Rosie'
						/>

						<Card
							img={philipines}
							content='	
			Hi Jasmine,How are you doing?? I hope your doing good.. just wanted to say thank you for all the help. So happy that my application been granted. God Bless! 😇
			'
							title='Kim'
						/>
					</Slider>
				</div>
			</div>
		</StyledReview>
	);
}

const Card = ({ img, title, content }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				textAlign: 'center',
			}}>
			<Styledp>{content}</Styledp>
			<Avatar
				imgProps={{ style: { borderRadius: '50%' } }}
				src={img}
				style={{
					width: 120,
					height: 120,
					border: '1px solid lightgray',
					padding: 7,
					marginTop: 30,
				}}
			/>

			<p style={styleF}>{title}</p>
		</div>
	);
};

const StyledReview = styled.div`
	margin: 120px auto;
	max-width: 1150px;
	width: 100%;

	.reviewHeading {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 5rem;
		line-height: 6rem;
		/* identical to box height */

		text-align: center;

		/* Black */

		color: #04152f;
		margin-bottom: 6rem;

		@media only screen and (max-width: 600px) {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 150%;
			/* or 42px */

			text-align: center;

			/* Black */

			color: #04152f;
		}
	}

	.slider {
		width: 75%;
		text-align: center;

		@media only screen and (max-width: 600px) {
			width: 55%;
		}
	}
`;

const Styledp = styled.p`
	font-family: SF Pro Display;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;
	/* or 30px */

	text-align: center;

	/* Black */

	color: #04152f;

	@media only screen and (max-width: 600px) {
		font-family: Ubuntu;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		/* or 150% */

		text-align: center;

		/* Black */

		color: #04152f;
	}
`;
export default Reviews;
