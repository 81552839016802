import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faBuilding,
	faCogs,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/fullLogo.svg';
import { useNavigate } from 'react-router-dom';

function Hamburger({ active, setActive }) {
	// style={{ right: active ? '0' : '-100%' }}
	const navigate = useNavigate();
	const clickHandler = (link) => {
		setActive(false);
		navigate(link);
	};

	const [isShown, setIsShown] = useState(false);

	const routeHandler = (route) => {
		setIsShown(false);
		setActive(false);

		navigate(route);
	};

	return (
		<StyledHamburger style={{ right: active ? '0' : '-100%' }}>
			<StyledNav>
				<HamburgerButton>
					<FontAwesomeIcon
						onClick={() => setActive(false)}
						className='icon icon-times'
						icon={faTimes}
						size='3x'
					/>
				</HamburgerButton>

				<NavLogo>
					<img src={Logo} alt='' />
				</NavLogo>

				<NavItems onMouseLeave={() => setIsShown(false)}>
					<NavItemlist onClick={() => setIsShown(!isShown)}>
						<div className='icon_div'>
							<FontAwesomeIcon
								// onClick={}
								className='icon icon-times'
								icon={faCogs}
								size='2x'
							/>
						</div>
						<div className='contenter'>
							<p>Services</p>
						</div>
						{isShown && (
							<StyledPopup>
								<li onClick={() => routeHandler('/studentvisa')}>
									Student Visas
								</li>
								<li onClick={() => routeHandler('/graduatevisa')}>
									Graduate Visas
								</li>
								<li onClick={() => routeHandler('/partnervisa')}>
									Partners Visas
								</li>
								<li onClick={() => routeHandler('/notfound')}>Skilled Visas</li>
								<li onClick={() => routeHandler('/notfound')}>
									Sponsored Visas
								</li>
								<li onClick={() => routeHandler('/notfound')}>
									Other Services
								</li>
							</StyledPopup>
						)}
					</NavItemlist>
					<NavItemlist onClick={() => clickHandler('/aboutus')}>
						<div className='icon_div'>
							<FontAwesomeIcon
								// onClick={}
								className='icon icon-times'
								icon={faBuilding}
								size='2x'
							/>
						</div>
						<div className='contenter'>
							<p>About Us</p>
						</div>
					</NavItemlist>
					<NavItemlist onClick={() => clickHandler('/contactus')}>
						<div className='icon_div'>
							<FontAwesomeIcon
								// onClick={}
								className='icon icon-times'
								icon={faPhone}
								size='2x'
							/>
						</div>
						<div className='contenter'>
							<p>Contact Us</p>
						</div>
					</NavItemlist>
				</NavItems>
			</StyledNav>
		</StyledHamburger>
	);
}

const StyledHamburger = styled.div`
	position: fixed;
	min-width: 40rem;
	height: 100vh;
	top: 0;
	right: 100%;
	box-shadow: 0px 4px 12px rgba(206, 206, 206, 0.25);

	/* border: 1px solid #001995; */
	border-bottom-left-radius: 3rem;
	background-color: #183fc7;
	color: #ffffff;
	transition: all 250ms ease;
	z-index: 999;
`;
const StyledNav = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	width: 100%;
	height: 95%;
	padding: 1.5rem 0rem;
`;

const NavLogo = styled.div`
	margin: 15rem 0rem 0rem 0rem;
`;

const NavItems = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 2rem 0rem;
	height: 75%;
	/* width: 50%; */
`;
const NavItemlist = styled.li`
	/* margin: 0rem 2rem; */
	font-family: 'Rubik', sans-serif;
	font-size: 1.9rem;
	line-height: 1.8rem;
	color: #ffffff;
	font-weight: 400;
	cursor: pointer;
	margin: 2rem 0rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.icon_div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30.4px;
		height: 30.4px;
	}

	.contenter {
		margin-left: 4rem;
		width: 80px;
		height: 15px;
	}
`;

const HamburgerButton = styled.div`
	transition: all 1s ease;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-right: 10rem;
	margin-top: 3rem;

	.icon-times {
		color: red;
	}
`;

const StyledPopup = styled.div`
	clip-path: polygon(0 7%, 29% 7%, 58% 0, 50% 8%, 100% 8%, 100% 100%, 0 100%);
	background-color: #ffffff;
	width: 20rem;
	position: absolute;
	top: 52vh;
	left: 6rem;
	padding-top: 2rem;
	z-index: 99;
	transistion: all 250ms ease;

	li {
		margin: 1rem 0rem;
		padding-bottom: 1rem;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 1.6rem;
		line-height: 2.2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: #000000;
		cursor: pointer;

		border-bottom: 1px solid black;
	}

	li:last-child {
		border-bottom: none;
	}
`;

export default Hamburger;
