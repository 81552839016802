import React from 'react';
import pick from '../images/underconstruction.webp';
import styled from 'styled-components';
import Logo from '../images/fullLogo.svg';
import { useNavigate } from 'react-router-dom';

function Notfound() {
	const navigate = useNavigate();

	return (
		<div
			style={{
				display: 'grid',
				placeItems: 'center',
				height: '100vh',
				fontSize: '2rem',
				color: 'black',
				width: '70%',
				maxWidth: '1000px',
				margin: '0 auto',
				textAlign: 'center',
				overflowX: 'hidden',
			}}>
			<LogoStyle onClick={() => navigate('/')}>
				<img src={Logo} alt='' />
			</LogoStyle>

			<h2
				style={{
					fontSize: '2rem',
					color: 'black',
					width: '70%',
					maxWidth: '1000px',
					margin: '0 auto',
					textAlign: 'center',
				}}>
				WE ARE CURRENTLY WORKING ON THIS PAGE. PLEASE COME BACK LATER
			</h2>
			<img style={{ width: '100%', margin: '0 auto' }} src={pick} alt='' />
		</div>
	);
}

const LogoStyle = styled.div`
	cursor: pointer;
	background: black;
`;

export default Notfound;
