import React from 'react';
import styled from 'styled-components';
import AboutDes from '../components/AboutDes';
import AboutHero from '../components/AboutHero';
import FormCom from '../components/FormCom';
import Footer from '../components/Footer';
import Growth from '../components/Growth';
import Schoollogo from '../components/Schoollogo';
import Team from '../components/Team';

function About({ active, setActive }) {
	return (
		<StyledAbout>
			<AboutHero active={active} setActive={setActive} />
			<AboutDes />
			<Growth />
			<Schoollogo />
			<Team />
			<FormCom title='Drop us a message and see how we can help you!' />
			<Footer />
		</StyledAbout>
	);
}

const StyledAbout = styled.div``;

export default About;
