import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Textfield from './formUI/Textfield';
import Grid from '@mui/material/Grid';
import emailjs from 'emailjs-com';
import Loader from 'react-loader-spinner';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import imagemessage from '../images/messageImage.svg';

function FormCom({ title }) {
	const INITIAL_FORM_STATE = {
		fullname: '',
		phone_number: '',
		email_address: '',
		service: '',
		added_message: '',
	};

	const FORM_VALIDATION = Yup.object().shape({
		fullname: Yup.string().required('Required'),
		email_address: Yup.string().email('Invalid email.').required('Required'),
		phone_number: Yup.string().required('Required'),
		service: Yup.string().required('Required'),
		added_message: Yup.string().required('Required'),
	});

	const [active, setActive] = useState(false);
	const [message, setMessage] = useState('');
	const [openChange, setOpenChange] = useState(false);
	const handleOpenChange = () => setOpenChange(true);
	const handleCloseChange = () => setOpenChange(false);

	const styleSettings = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '600px',
		width: '95%',
		bgcolor: '#F4F4F5',
		boxShadow: 24,
		borderRadius: '10px',
		outline: 'none',
		border: 'none',
	};

	return (
		<StyledForm>
			<div className='wrap'>
				<div className='content'>
					<div className='left'>
						<h2>{title}</h2>
						<p className='leftp'>
							We are pleased to speak with you to discuss your qualifications
							and options under the various Australia Migration programs and
							answer any questions or concerns you may have.
						</p>
						<div className='phone'>
							<h4>Phone</h4>
							<p>+61 451767518</p>
						</div>
						<div className='email'>
							<h4>Email</h4>
							<p>info@olamigration.com.au</p>
						</div>
					</div>

					<div className='right'>
						<Formik
							initialValues={{
								...INITIAL_FORM_STATE,
							}}
							validationSchema={FORM_VALIDATION}
							onSubmit={(values, { resetForm }) => {
								setActive(true);
								try {
									emailjs
										.send(
											'service_r5xvlyv',
											'template_1972pej',
											values,
											'user_HUWOT064hjlH0UnAly9fa'
										)
										.then((res) => {
											setActive(false);
											setMessage('Thank you, we will reach out soon');
											handleOpenChange();
											resetForm();
										});
								} catch (err) {
									setActive(false);
									alert('failed to submit form');

									console.log(err);
								}
							}}>
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h2 className='form_title'>{title}</h2>
									</Grid>

									<Grid item xs={12}>
										<Textfield
											size='medium'
											name='fullname'
											label='Full Name*'
										/>
									</Grid>

									<Grid item xs={12}>
										<Textfield
											size='medium'
											type='number'
											name='phone_number'
											label='Phone*'
										/>
									</Grid>

									<Grid item xs={12}>
										<Textfield
											size='medium'
											name='email_address'
											label='Email Address*'
										/>
									</Grid>

									{/* <Grid item xs={12}>
										<Textfield
											size='medium'
											name='service'
											label='Select a Service Need*'
										/>
									</Grid> */}

									<Grid item xs={12}>
										{/* <label htmlFor='service'>Select a Service Need*</label> */}
										<Field className='service' as='select' name='service'>
											<option>Select a Service Need*</option>

											<option value='Student Visa'>Student Visa</option>
											<option value='Graduate Visa'>Graduate Visa</option>
											<option value='Partner Visa'>Partner Visa</option>
											<option value='Skilled Visa'>Skilled Visa</option>
											<option value='Employer Sponsored Visa'>
												Employer Sponsored Visa
											</option>
											<option value='Other Migration Visa'>
												Other Migration Visa
											</option>
										</Field>
									</Grid>

									<Grid item xs={12}>
										<Textfield
											multiline
											rows={8}
											name='added_message'
											label='Add Additional Message*'
										/>
									</Grid>

									<Grid item xs={12}>
										{/* <ButtonWrapper>Submit Form</ButtonWrapper> */}
										<button
											disabled={active ? true : false}
											className='form_button'
											type='submit'>
											<p>Submit Form</p>
											<Loader
												type='Puff'
												color='#ffffff'
												height={30}
												width={30}
												visible={active ? true : false}
												className='loader'
											/>
										</button>
									</Grid>
								</Grid>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
			<Modal
				open={openChange}
				onClose={handleCloseChange}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={styleSettings}>
					<Wrapper>
						<img className='wrapper_img' src={imagemessage} alt='' />
						<h2 className='wrapper_h2'>Success!</h2>
						<p className='wrapper_p'>{message}</p>
					</Wrapper>
				</Box>
			</Modal>
		</StyledForm>
	);
}

const StyledForm = styled.div`
	background: rgba(233, 235, 238, 0.2);
	width: 100%;
	padding: 0 15px;
	@media only screen and (max-width: 480px) {
		padding: 0 0;
	}

	.wrap {
		margin: 0 auto;
		max-width: 1150px;
		width: 100%;
	}

	.content {
		/* width: 75%; */

		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 6rem 0 12rem 0;

		@media only screen and (max-width: 940px) {
			flex-direction: column-reverse;
		}
	}

	.left {
		flex: 1;
		padding-right: 15rem;

		@media only screen and (max-width: 940px) {
			padding-right: 8rem;
			margin-top: 20px;
		}

		@media only screen and (max-width: 480px) {
			padding-left: 1rem;
		}
		.phone {
			margin-top: 4rem;
		}

		.email {
			margin-top: 4rem;
		}
		h2 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 5rem;
			line-height: 6rem;
			display: flex;
			align-items: center;
			color: #04152f;
			margin-bottom: 3rem;

			@media only screen and (max-width: 940px) {
				display: none;
			}
		}
		h4 {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 3rem;
			line-height: 150%;
			color: #04152f;
		}
		p {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 2.4rem;
			line-height: 150%;
			color: #04152f;
		}

		.leftp {
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: normal;
			font-size: 2rem;
			line-height: 150%;
			color: #04152f;
		}
	}

	.right {
		flex: 1;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		background: #ffffff;
		padding: 5rem;
		height: 60rem;
		box-shadow: 0px -4px 4px rgba(193, 188, 188, 0.1),
			0px 4px 4px rgba(193, 188, 188, 0.1);

		@media only screen and (max-width: 1200px) {
			height: auto;
		}

		@media only screen and (max-width: 480px) {
			padding: 5rem 1rem;
		}

		.service {
			width: 100%;
			height: 45px;
			outline: none;
			padding: 0 10px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 4px;
			background: url(https://i.ibb.co/ftFbJHZ/down-arrow.png) 96% / 2%
				no-repeat #ffffff;

			option {
				font-weight: 100;
			}
		}

		.form_title {
			display: none;
			font-family: SF Pro Display;
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 150%;
			/* or 42px */

			/* display: flex; */
			align-items: center;

			/* Brand/Dark */

			color: #001995;

			@media only screen and (max-width: 940px) {
				display: flex;
			}
		}
		h6 {
			font-size: 1.7rem;
		}

		.form_button {
			width: 100%;
			height: 4.6rem;
			background: #183fc7;
			border-radius: 5px;
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 1.5rem;
			line-height: 1.8rem;
			text-align: center;
			color: #ffffff;
			outline: none;
			border: none;
			margin: 3rem 0;
			cursor: pointer;
			position: relative;
			/* transition: all 5s ease */

			.loader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				transform: translateY(-1rem);
			}
		}
	}
`;

const Wrapper = styled.div`
	display: grid;
	place-items: center;
	margin: 20px 0;

	.wrapper_h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		text-align: center;
		color: #002841;
		margin: 2rem 0;
	}

	.wrapper_p {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		text-align: center;
		color: rgba(0, 40, 65, 0.8);
		margin: 1rem 0;
	}
`;

export default FormCom;
