import React from 'react';
import styled from 'styled-components';
import TextTruncate from 'react-text-truncate';
import { Link } from 'react-router-dom';
// import Skeleton from '@mui/material/Skeleton';

function Card({ item, loading }) {
	return (
		<StyledCard>
			<Link to={item.link} className='linkOn'>
				<div className='image'>
					<img src={item.image} loading='lazy' alt='' />
				</div>
				<div className='title'>
					<h4>{item.title}</h4>
				</div>

				<div className='content'>
					<p>
						<TextTruncate
							line={3}
							element='span'
							truncateText='…'
							text={item.content}
						/>
					</p>
				</div>
			</Link>
		</StyledCard>
	);
}

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	background: #ffffff;
	box-shadow: 0px 4px 12px rgba(206, 206, 206, 0.25);
	border-radius: 5px;
	padding: 1rem;
	height: 46rem;
	min-width: 350px;
	/* max-width: 350px; */

	.image {
		width: 100%;
		img {
			width: 100%;
			height: 24.9rem;
			object-fit: cover;
		}
	}

	.title {
		font-family: SF Pro Display;
		font-style: normal;
		font-weight: 600;
		font-size: 2.4rem;
		line-height: 150%;
		color: #04152f;
		margin: 3rem 0 1.5rem 0;
	}

	.content {
		p {
			font-family: SF Pro Text;
			font-style: normal;
			font-weight: normal;
			font-size: 2rem;
			line-height: 150%;
			color: #04152f;
		}

		.linkOn {
			color: #183fc7;
		}
	}
`;

export default Card;
